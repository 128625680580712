<!-- HEADER
			============================================= -->
<header id="header" class="tra-menu navbar-dark inner-page-header white-scroll">
    <div class="header-wrapper">


        <!-- MOBILE HEADER -->
        <div class="wsmobileheader clearfix">
            <span  ><img style="max-height: 100px;" routerLink="/" [src]="server + platform.lightLogo.public_url" alt="mobile-logo"></span>
       
        </div>


        <!-- NAVIGATION MENU -->
        <div class="wsmainfull menu clearfix">
            <div class="wsmainwp clearfix">


                <!-- HEADER BLACK LOGO -->
                <div class="desktoplogo">
                    <a routerLink="/" class="logo-black"><img [src]="server + platform.lightLogo.public_url" alt="logo"></a>
                </div>


                <!-- HEADER WHITE LOGO -->
                <div class="desktoplogo">
                    <a routerLink="/" class="logo-white"><img [src]="server + platform.lightLogo.public_url" alt="logo"></a>
                </div>


                <!-- MAIN MENU -->
                <nav class="wsmenu clearfix">
                    <ul class="wsmenu-list nav-theme">


                       
                    </ul>
                </nav> <!-- END MAIN MENU -->


            </div>
        </div> <!-- END NAVIGATION MENU -->


    </div> <!-- End header-wrapper -->
</header> <!-- END HEADER -->