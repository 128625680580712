			<!-- FOOTER-3
			============================================= -->
			<footer id="footer-3" class="pt-100 footer ft-3-ntr">
				<div class="container">


					<!-- FOOTER CONTENT -->
					<div class="row">


						<!-- FOOTER LOGO -->
						<div class="col-xl-3">
							<div class="footer-info">
								<img class="footer-logo" [src]="server + platform.lightLogo.public_url" alt="footer-logo">
							</div>	
						</div>	


					

				
					


					</div>	<!-- END FOOTER CONTENT -->


					<hr>	<!-- FOOTER DIVIDER LINE -->


					<!-- BOTTOM FOOTER -->
					<div class="bottom-footer">
						<div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">


							<!-- FOOTER COPYRIGHT -->
							<div class="col">
								<div class="footer-copyright">
									<p class="p-sm">&copy; 2023 {{platform.companyName}}. <span>All Rights Reserved</span></p>
								</div>
							</div>


							<!-- FOOTER SOCIALS -->
							<div class="col">
								<ul class="bottom-footer-socials ico-20 text-end">
									<li><a href="{{platform.facebookLink}}"><span class="flaticon-facebook"></span></a></li>
									<li><a href="{{platform.twitterLink}}"><span class="flaticon-twitter"></span></a></li>
							
									<li><a href="{{platform.youtubeLink}}"><span class="flaticon-youtube"></span></a></li>
								</ul>
							</div>


						</div>  <!-- End row -->
					</div>	<!-- END BOTTOM FOOTER -->


				</div>     <!-- End container -->	
			</footer>   <!-- END FOOTER-3 -->	