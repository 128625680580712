<div id="page" class="page font--jakarta">
  <app-header [platform]="platform"></app-header>


  <router-outlet></router-outlet>

  <app-footer [platform]="platform"></app-footer>




  <!-- END PAGE CONTENT -->


</div>

<ngx-scrolltop [size]="50" position="left"></ngx-scrolltop>