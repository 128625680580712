import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getHeader = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info/site-header`)

  getTeam = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info/site-team`)

  getCompanyInfo = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info/company-info`)

  getPlatformInfo = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info`)

  sendMessage(data: any) {
    return this.http.post(`${environment.BASE_URL}${environment.API_TAG}/contacts`, data)
  }

  getCustomerInfo = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info/customer-info`)

  getContactInfo = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/platform-info/contact-info`)

  getWorkers = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/workers`)

  getStatistics = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/statistics`)

  getServices = () => this.http.get(`${environment.BASE_URL}${environment.API_TAG}/service-categories`)

  createMessage = (data: any) => this.http.post(`${environment.BASE_URL}${environment.API_TAG}/contacts`, data)
}
