import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './core/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  platform: any;

  constructor(private apiService: ApiService, private router: Router) {

  }

  getPlatformInfo = () => this.apiService.getPlatformInfo().subscribe(
    (res: any) => this.platform = res.data
  )

  ngOnInit() {
    this.getPlatformInfo()
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}